import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Header = styled.div`
  height: 10rem;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: -10%;
    height: 30px;
    width: 120%;
    background: #ffffff;
    transform: translateY(50%) rotate(5deg);
  }
`;

const Ribbon = styled.div`
  position: absolute;
  width: 130px;
  right: -30px;
  top: 25px;
  font-size: 0.6em;
  transform: rotate(45deg);
`;

const CourseCard = ({
  name,
  subtitle,
  img,
  description,
	comingSoon,
	className,
}) => {
  let ribbon = null;
  if (comingSoon) {
    ribbon = (
      <Ribbon className='text-white bg-red p-1 uppercase tracking-wide text-center shadow font-bold'>
        Coming Soon
      </Ribbon>
    );
  }

  return (
    <div className={`relative overflow-hidden rounded-lg shadow-md bg-white flex flex-col w-full ${className}`}>
      <Header
        className="relative bg-cover z-0 bg-black"
        style={{ backgroundImage: `url(${img})` }}
      />

      {ribbon}

      <div className="relative p-6 -mt-4 flex flex-col flex-1">
        <h2 className="mb-2 text-lg text-grey-darker font-normal leading-tight">
          {name}
        </h2>
        <div className="mb-6 text-xs tracking-wide uppercase text-grey">
          {subtitle}
        </div>

        <div className="text-grey-dark tracking-normal leading-normal text-sm flex-1">
          {description}
        </div>
      </div>
    </div>
  );
};

CourseCard.propTypes = {
  name: PropTypes.string.isRequired,
	img: PropTypes.string.isRequired,
	subtitle: PropTypes.string.isRequired,
	description: PropTypes.node.isRequired,
	comingSoon: PropTypes.bool,
};
CourseCard.defaultProps = {
  comingSoon: false,
};

export default CourseCard;
