import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import { navigate } from '@reach/router';

import Layout from '../components/Layout';
import './index.css';
import Section from '../components/Section';
import Testimonials from '../components/Testimonial/Testimonials';
import WaitlistFull from '../components/WaitlistFull';
import Totals from '../components/Totals';
import MakersWithBio from '../components/Makers/MakersWithBio';
import CommunityImgs from '../components/CommunityImgs';
import Plans from '../components/Plans';
import Callout from '../components/Callout';
import Button from '../components/Button';
import { IfClosed, IfOpen } from '../components/OpenFlags';
import Header from '../components/Header';
import CourseCard from '../components/CourseCard';

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "diresta/diresta_hero.jpg" }) {
      data: childImageSharp {
        fixed(width: 1800, quality: 80) {
          src
        }
      }
    }
    headshot: file(relativePath: { eq: "diresta/diresta_headshot.jpg" }) {
      data: childImageSharp {
        fixed(width: 800, quality: 80) {
          src
        }
      }
    }
    canoe: file(relativePath: { eq: "diresta/canoe.jpg" }) {
      data: childImageSharp {
        fixed(width: 300, quality: 80) {
          src
        }
      }
    }
    paddles: file(relativePath: { eq: "diresta/paddles.jpg" }) {
      data: childImageSharp {
        fixed(width: 300, quality: 80) {
          src
        }
      }
    }
    shaveBox: file(relativePath: { eq: "diresta/shave_box.jpg" }) {
      data: childImageSharp {
        fixed(width: 300, quality: 80) {
          src
        }
      }
    }
    stoveSideTables: file(
      relativePath: { eq: "diresta/stove_side_tables.jpg" }
    ) {
      data: childImageSharp {
        fixed(width: 300, quality: 80) {
          src
        }
      }
    }
  }
`;

class JimmyDirestaPage extends React.Component {
  handleInputChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const maker = 'diresta';
    const { data } = this.props;
    const heroImg = get(data, 'hero.data.fixed.src');
    const canoeImg = get(data, 'canoe.data.fixed.src');
    const paddlesImg = get(data, 'paddles.data.fixed.src');
    const shaveBoxImg = get(data, 'shaveBox.data.fixed.src');
    const stoveSideTablesImg = get(data, 'stoveSideTables.data.fixed.src');
    const renderThankYou = this.props.location.search === '?thankyou=true';

    return (
      <Layout pageTitle="Jimmy DiResta">
        <div
          className="bg-black items-center bg-no-repeat"
          style={{
            backgroundImage: `url(${heroImg})`,
            backgroundPosition: '25% 20%',
            backgroundAttachment: 'local',
            backgroundSize: 'cover',
          }}
        >
          <Header maker={maker} />

          <div className="container mx-auto">
            <div className="py-32 md:flex">
              <div className="md:w-1/2 text-center" />
              <div className="md:w-1/2 px-8">
                <h1 className="text-grey-lighter text-5xl mb-2 antialiased uppercase">
                  Jimmy DiResta
                </h1>
                <h2 className="text-grey-light text-md mb-8 font-normal antialiased uppercase">
                  Maker &amp; Artist
                </h2>
                <p className="text-white leading-normal tracking-normal mb-8">
                  Since I was a little kid in my dad’s shop I have been
                  experimenting with tools and materials. Learning how to use
                  and implement them. Now 40+ years later that's all I have been
                  doing: Making things and teaching how to make things involving
                  metal wood plastic and more.
                </p>

                <IfOpen>
                  <Button onClick={e => navigate(`/plans?ref=${maker}`)}>
                    Join Makers Mob
                  </Button>
                </IfOpen>
              </div>
            </div>
          </div>
        </div>

        <div>
          <IfClosed>
            <WaitlistFull
              redirectUrl="https://www.joinmakersmob.com/diresta/?thankyou=true"
              listId="awlist5217590"
              subscribed={renderThankYou}
              makersName="Jimmy DiResta"
            />
          </IfClosed>
        </div>

        <div>
          <div className="text-2xl text-center text-grey-darkest font-bold mb-4 mt-24">
            Every All Access Makers Mob Membership Includes:
          </div>
          <Totals />

          <div className="text-2xl text-center text-grey-darkest font-bold mb-2">
            Inside You'll Find 4 Exclusive Tutorials by Jimmy Diresta
          </div>
          <div className="text-base text-center text-grey-dark uppercase tracking-wide mb-12">
            with more added every month
          </div>

          <div className="md:flex mb-24 px-12">
            <CourseCard
              name="Canoe Build"
              img={canoeImg}
              subtitle="16 Lessons - Length 1:52:00"
              description={
                <p>
                  In this 16 part series you will be able to follow along as I
                  share with you how I build this striking 15 foot cedar strip
                  canoe. If you have ever been on the fence about building a
                  canoe, I cannot stress enough, what a tremendous learning
                  experience it is… and the best part? You end up with a
                  gorgeous canoe when you are all done.
                </p>
              }
              className="mx-2 mb-4"
            />

            <CourseCard
              name="Canoe Paddles"
              img={paddlesImg}
              subtitle="3 Lessons - Length 0:25:00"
              description={
                <p>
                  In this 3 part series I will show you how I made my very first
                  canoe paddles. From the rough cut, to the plaining, to the
                  finishing, I will share with you what went great and what I
                  learned along the way. I know that you will definitely get
                  something out of this, but the best learning experience truly
                  comes by doing it yourself.
                </p>
              }
              className="mx-2 mb-4"
            />

            <CourseCard
              name="Custom Shave Box"
              img={shaveBoxImg}
              subtitle="3 Lessons - Length 0:22:00"
              description={
                <p>
                  Through this 3 part series I will take you step by step how I
                  built this custom shave box. I will share with you how I was
                  able to TIG weld the corners, sand them back to shape, and get
                  it all buffed, to make it pop! Finally, I will show you how I
                  built the walnut lid cover that really brought the whole build
                  together.
                </p>
              }
              className="mx-2 mb-4"
            />

            <CourseCard
              name="Stove Side Cabinets"
              img={stoveSideTablesImg}
              subtitle="Coming Soon"
              comingSoon={true}
              description={
                <p>
                  <strong>Coming soon!</strong> In this series I will share with
                  you how I took a very simple approach so that the amateur
                  builder will be able to attempt a very similar build. The
                  plywood end grain was filled in with 3 coats of paint with
                  sanding in-between coats. It was a fun project that I am sure
                  you will enjoy!
                </p>
              }
              className="mx-2 mb-4"
            />
          </div>

          <div className="mb-24">
            <div className="text-2xl text-center text-grey-darkest font-bold mb-8">
              A Quick Preview of What You'll Find Inside
            </div>

            <div className="flex justify-center">
              <iframe
                src="https://player.vimeo.com/video/321349819"
                width="640"
                height="360"
                frameborder="0"
                webkitallowfullscreen
                mozallowfullscreen
                allowfullscreen
              />
            </div>
          </div>
        </div>

        <Callout variation="workbench" maker={maker} />

        <Section title="What Our Members are Saying..." type="dark">
          <Testimonials />
        </Section>

        <Section title="Plans, Material Lists, Cut Lists &amp; More">
          <Plans />
        </Section>

        <Section title="Exclusive Private Member's Community" type="dark">
          <div className="mb-32">
            <div className="text-grey-dark mb-8 -mt-6 text-xl text-center">
              Probably the Most Valuable Aspect of the School...
            </div>
            <div className="text-grey-light md:w-2/3 mx-4 md:mx-auto leading-normal">
              A PRIVATE Group, where THOUSANDS of Woodworkers from all over the
              World Get Together and Inspire each other! Also, Every week we
              host a LIVE Hangout where you can ask questions and interact with
              1000's of fellow woodworkers.
            </div>

            <CommunityImgs />
          </div>
        </Section>

        <div className="mt-24">
          <div className="text-2xl text-center text-grey-darkest font-bold mb-8">
            Meet the Makers
          </div>

          <div className="container mx-auto">
            <MakersWithBio defaultMaker="diresta" />
          </div>
        </div>

        <Callout variation="diresta" maker={maker} />

        <script
          type="text/javascript"
          src="https://app.paykickstart.com/tracking-script"
        />
      </Layout>
    );
  }
}

export default JimmyDirestaPage;
